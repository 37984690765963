import React from 'react';
import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';
import CookieConsent from "react-cookie-consent";
import SubFooter from './SubFooter';
import '../scss/style.scss';
import SideMenu from "./SideMenu";
import {Link} from "gatsby";

const Layout = props => (
  <>
      <CookieConsent
          location="bottom"
          buttonText="Enig"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
      >
          Vi bruker cookies og dine data til å måle reklame, innhold og kommunikasjon. Les mer om det i vår <Link style={{color:"white"}} to={"/privacy-policy"}>Personvernerklæring</Link>.
      </CookieConsent>
    <SEO />
    <div className={`container mt-6 page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
      <div id="wrapper" className="wrapper mx-2">
          <Header />
          <div className={"d-flex justify-content-center"}>
            {props.children}
            {!!props.showRelated &&
                <div className={"d-none pt-10 d-md-block ml-2"}>
                    <SideMenu/>
                </div>
            }
          </div>
          {!!props.showRelated &&
          <div className={"d-flex d-sm-block d-md-none"}>
              <SideMenu fullWidth={true}/>
          </div>
          }
      </div>
      <br></br>
      <br></br>
      {<SubFooter />}
    </div>
  </>
);

export default Layout;
