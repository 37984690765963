import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import AdSense from 'react-adsense';
import Dayflow from '../../static/images/illustrations/Dayflow.png';

const SideMenu = props => {

  const data = useStaticQuery(graphql`
    query SideMenuQuery {
      allQuizlistJson {
        edges {
          node {
            name
            beskrivelse
            category
            link
          }
        }
      }
    }
  `);

  return (
    <div className="side-menu">
      <h4 className="d-flex align-items-end mb-n1">
        <div className="mb-2">Andre quizer</div>
      </h4>
      {data.allQuizlistJson.edges
        .filter(it => typeof window !== `undefined` && it.node.link !== window.location.pathname)
        .map(({ node }) => (
          <Link to={node.link} className={`card my-2 ${node?.category}`} style={{width: "18rem"}}>
            <div className="card-body">
              <h4 className="card-title bold h4">{node.name}</h4>
              <div className="card-text q-text-sm">{node.beskrivelse}</div>
            </div>
          </Link>
        ))
      }
    </div>
  );
};

export default SideMenu;
